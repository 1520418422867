import { FormatDateToMonthYear } from '../../../../Constants';
import { Th } from '@chakra-ui/react';
import { copyPreviousForecast } from '../OtherLevel/OtherCanvasHelper';

// import { useNavigate } from 'react-router-dom';

export const SKURowNames = [
  'YAG Purchases',
  'Prev Fcst',
  'Stat Fcst',
  'Current Forecast',
];

export const LAST_CYCLE_FORECAST = 'qty_last_cycle_forecast';
export const OVERRIDE_FORECAST = 'qty_override_forecast';

export const SKUTypelist = ['qty_year_ago_history', 
  LAST_CYCLE_FORECAST, 
  'qty_stat_baseline', 
  OVERRIDE_FORECAST, 
  'total'
];

const totalKeys = ["qty_override_forecast","qty_year_ago_history","qty_stat_baseline"]


/*
Generate table headers from the received response
*/
export const GetTableHeaders = (dates) => {
    return dates.map((date) => <Th textAlign key={date}>{FormatDateToMonthYear(date)}</Th>);
};


/*
Calculate total for the rolling 12 months
*/
export const YearEndTotal = (arr) => arr.reduce((acc, curr) => acc + (isNaN(curr) ? 0 : curr), 0);

/*
Get all the unique values to show in the selection component
*/
export const GetUniqueValues = (array, key) => {
  return [...new Set(array.map((item) => item[key]))]
};
  

export const createGroupedData=(data1,selectedDealer,selectedCategory,selectedProductLine)=>{
  const filteredData = data1?.filter(
    (item) =>
      (!selectedProductLine || item['Product_Line'] === selectedProductLine) &&
      (!selectedCategory || item['Product_Category'] === selectedCategory) &&
      (!selectedDealer || item['dealer_no_all']===selectedDealer)
  );
  const groupedData = filteredData.reduce((grouped, item) => {
    const dealer_skuCode = item["dealer_no"]+"__"+item['SKU_code']+"__"+item['forecast_version_date'];
    grouped[dealer_skuCode] = grouped[dealer_skuCode] || [];
    grouped[dealer_skuCode].push(item);
    return grouped;
  }, {});
  return groupedData
}

export const createGroupedDataReport=(data1,selectedDealer,selectedCategory,selectedProductLine)=>{
  const filteredData = data1?.filter(
    (item) =>
      (!selectedProductLine || item['Product_Line'] === selectedProductLine) &&
      (!selectedCategory || item['Product_Category'] === selectedCategory) &&
      (!selectedDealer || item['dealer_no_all']===selectedDealer)
  );
  const groupedData = filteredData.reduce((grouped, item) => {
    const dealer_skuCode = item["dealer_no"]+"__"+item['SKU_code']+"__"+item['forecast_version_date'];
    grouped[dealer_skuCode] = grouped[dealer_skuCode] || [];
    grouped[dealer_skuCode].push(item);
    item["qty_override_forecast"]=copyPreviousForecast(item["qty_override_forecast"],copyPreviousForecast(item["qty_last_cycle_forecast"],item["qty_stat_baseline"]))
    return grouped;
  }, {});
  Object.entries(groupedData).forEach(([key,value])=>{
    let totals={}
    value.forEach((item)=>{
    totalKeys.forEach((key1)=>{
        totals[key1]=(isNaN(parseFloat(totals[key1]))?0:parseFloat(totals[key1]))+(isNaN(parseFloat(item[key1]))?0:parseFloat(item[key1]))
    })
    })
    value.push(totals)
})
  return groupedData
}