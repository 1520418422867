import React, { useState, useEffect, useMemo } from "react";
import { Box, HStack, Spacer,
  Text,
  Button
  } from '@chakra-ui/react'
import { 
  GetTableHeaders, SKURowNames, GetUniqueValues 
} from "./SKUCanvasHelper";
import { IoMdDownload } from 'react-icons/io';
import { CustomSpinner } from "../../../Spinner";
import { ProductLine, Category,Dealer } from './Filter';
import '../../../../index';
import { createGroupedDataReport } from "./SKUCanvasHelper";
import useFetch from "../../../../hooks/useFetchWithMsal";
import { FormatDateToMonthYear } from "../../../../Constants";
import { SKUTypelist } from "./SKUCanvasHelper";
import TextWrapRenderer from "../TextWrapRenderer";
import { ReactGrid } from "@silevis/reactgrid";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";



function SKUReport({userProfile}) {

  const [{ data, loading:isLoading, }, fetchData] = useFetch([]);
  const uniqueDates = GetUniqueValues(data, 'date'); // Column header values for dates
  const uniqueProductCategory = useMemo(() => GetUniqueValues(data, 'Product_Category'), [data]);
  const [selectedProductLine, setSelectedProductLine] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const uniqueDealers=GetUniqueValues(data,'dealer_no_all')
  const uniqueProductLine = GetUniqueValues(data, 'Product_Line');
  const [filteredUniqueProductCategory, setFilteredUniqueProductCategory] = useState(uniqueProductCategory);
  const [selectedDealer,setSelectedDealer]=useState(uniqueDealers[0])
  const [groupedData,setGroupedData]=useState(createGroupedDataReport(data,selectedDealer,selectedCategory,selectedProductLine))

  
  const exportToCSV = () => {
    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "SKUReport"
    const wb = XLSX.utils.book_new();
    const arrayToPrint = rows.map((item)=>{
        return item["cells"].map((item2)=>{
          return item2["text"]?item2["text"]:item2["value"]
        })
    })
    const ws = XLSX.utils.aoa_to_sheet(arrayToPrint);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data1 = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data1, fileName + fileExtension);
  };
  
  
  const getColumns = (uniqueDates) => {
    if (uniqueDates.length === 0) return [];
    return [{ columnId: "dealer_no", width: 150, resizable:true},
            { columnId: "SKU_Description", width: 150, resizable:true},
            { columnId: "SKU_Code", width: 150, resizable:true},
            { columnId: "Key_Figure", width: 150, resizable:true}, 
            ...uniqueDates.map((column) => {
      return { columnId: column, width: 75, resizable:true}
    }),{columnId:"Totals",width:90,resizable:true}];
  }

  const getRows = (groupedData) => {
    if (Object.keys(uniqueDates).length === 0) return [];
    const headerRowCells = uniqueDates.map((column) => {
      return { type: "header", text: FormatDateToMonthYear(column),className:"header-class" }
    })
    let rows = [
      {
        rowId: "header",
        cells: [{ type: "header", text: "Dealer Number",className:"header-class" },{ type: "header", text: "SKU Description",className:"header-class"  },{ type: "header", text: "SKU Code",className:"header-class"  },
        { type: "header", text: "Key Figure",className:"header-class"  }, ...headerRowCells,{type: "header", text: "Totals",className:"header-class"}]
      },
    ]
    let dictionaryRow=[...rows]
    Object.keys(groupedData).forEach((rowName,index1) => {
      SKURowNames.forEach((key_figure_name,index2)=>{
        const className1 =key_figure_name==='Current Forecast'?"number-class1":"number-class2"
        let oneSingleRow =  {
          rowId: `${rowName}__${SKUTypelist[index2]}`,
          cells: [{type:"text",nonEditable:true,text:groupedData[rowName][0]["dealer_no"],renderer:(text)=>{return <TextWrapRenderer value={text} hover={groupedData[rowName][0]["dealer_name"]} />},className:key_figure_name==='Current Forecast'?"row-class":"row-class2"},
          {type:"text",nonEditable:true,text:groupedData[rowName][0]["SKU_Description"],renderer:(text)=>{return <TextWrapRenderer value={text} hover={text} />},className:key_figure_name==='Current Forecast'?"row-class":"row-class2"},
          {type:"text",nonEditable:true,text:groupedData[rowName][0]["SKU_code"],className:key_figure_name==='Current Forecast'?"row-class":"row-class2"},
          {type:"text",nonEditable:true,text:key_figure_name,className:key_figure_name==='Current Forecast'?"row-class":"row-class2"},
          ...groupedData[rowName].map((value) => {
            return { type: "number",nonEditable:true ,value: isNaN(parseFloat(value[SKUTypelist[index2]])) ? "": parseFloat(value[SKUTypelist[index2]]),className:className1 }
          }
          )]
        }
      dictionaryRow.push(oneSingleRow)
      })
    })
      return dictionaryRow;
    }
  

  const rows=getRows(groupedData)
  const [columns, setColumns] = useState(getColumns(uniqueDates));
  const handleColumnResize = (ci, width) => {
    if (uniqueDates.length ===0) return [];
    setColumns((prevColumns) => {
        const columnIndex = prevColumns.findIndex(el => el.columnId === ci);
        const resizedColumn = prevColumns[columnIndex];
        const updatedColumn = { ...resizedColumn, width };
        prevColumns[columnIndex] = updatedColumn;
        return [...prevColumns];
    });
}

  useEffect(() => {
    if (!userProfile) return;
    fetchData(`/regional_products?userID=${userProfile["ID"]}`, {method: "GET"}).then((data1) => {
      const uniqueProductCategory = GetUniqueValues(data1, 'Product_Category');
      const uniqueDealers=GetUniqueValues(data1,'dealer_no_all')
      const uniqueProductLine = GetUniqueValues(data1, 'Product_Line');
      setFilteredUniqueProductCategory(uniqueProductCategory);
      setSelectedDealer('')
      setColumns(getColumns(GetUniqueValues(data1,'date')))
      setGroupedData(createGroupedDataReport(data1,'','',''))
    })
  }, [userProfile,setFilteredUniqueProductCategory ,fetchData]);

  useEffect(() => {
    if (!userProfile) return;
    setGroupedData(createGroupedDataReport(data,selectedDealer,selectedCategory,selectedProductLine))
}, [userProfile,data ,selectedDealer, selectedCategory,selectedProductLine]);

  /* 
  Show Spinner until the data is received
  */
  if (isLoading) return <CustomSpinner text="Loading Report..." />

  const handleProductLineChange = (selectedOption) => {
    setSelectedProductLine(selectedOption);
    setSelectedCategory('');
    const filteredCategories = data
    .filter(item => selectedOption ? item['Product_Line'] === selectedOption : true)
    .map(item => item['Product_Category']);
    setFilteredUniqueProductCategory([...new Set(filteredCategories)]);
  }
  const handleCategoryChange = (selectedOption) => setSelectedCategory(selectedOption);
  const handleDealerChange = (selectedOption)=>setSelectedDealer(selectedOption);

  return (
    <Box height="70vh" overflow="auto">
      <HStack spacing={4}>
        <ProductLine value={selectedProductLine} options={uniqueProductLine} onChange={handleProductLineChange}/>
        <Category value={selectedCategory} options={filteredUniqueProductCategory} onChange={handleCategoryChange}/>
        <Dealer value={selectedDealer} options={uniqueDealers} onChange={handleDealerChange}/>
        <Button leftIcon={<IoMdDownload />} onClick={exportToCSV} colorScheme='blue'>
          Export
        </Button>
        <Spacer />
        <Text
        fontSize="sm" // Adjust the font size to your preference
        fontWeight="bold" // Apply bold font weight to the caption
        color="gray.500" // Use a gray color for the caption
        marginBottom="2" // Add some bottom margin for spacing
        >* Data in units</Text>
        </HStack>
      <Box className="react-grid" id="printableArea">
        <ReactGrid  rows={rows} columns={columns} disableVirtualScrolling={true} stickyLeftColumns={4} stickyTopRows={1} stickyRightColumns={1} onColumnResized={handleColumnResize} enableColumnSelection enableRangeSelection enableRowSelection />
      </Box>
    </Box>
  );
}

export default SKUReport;