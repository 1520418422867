import React from 'react';
import { Flex, FormLabel, Select } from '@chakra-ui/react';

/**
 * 
 * @param {*} param0 option values and onChange callback
 * @returns Selector component with unique product line values
 */
export function ProductLine({ value = '', options, onChange }) {
    const handleProductLineChange = (event) => {
        const selectedOption = event.target.value;
        onChange(selectedOption);
    };
    return (
        <Flex alignItems="center">
            <FormLabel htmlFor="productLine" 
                mb="0" mr="2"
                color="gray.600" fontWeight="bold"
                fontSize="sm">
                Product Family
            </FormLabel>
            <Select 
                id="productLine"
                placeholder="Select All"
                onChange={handleProductLineChange}
                value={value}
            >
                {options.map((value, index) => (
                    <option value={value} key={index}>{value}</option>
                ))}
            </Select>
        </Flex>
    )
};

/**
 * 
 * @param {*} param0 option values and onChange callback 
 * @returns Selector component with unique option values
 */
export function Category({ value='', options, onChange }) {
    const handleCategoryChange = (event) => {
        const selectedOption = event.target.value;
        onChange(selectedOption);
    }
    return (
        <Flex alignItems="center">
            <FormLabel htmlFor="category" 
                mb="0" mr="2"
                color="gray.600" fontWeight="bold"
                fontSize="sm">
                Product Code
            </FormLabel>
            <Select 
                id="category"
                placeholder="Select All"
                onChange={handleCategoryChange}
                value={value}
            >
                {options.map((value, index) => (
                    <option value={value} key={index}>{value}</option>
                ))}
            </Select>
        </Flex>
    )
};

/**
 * 
 * @param {*} param0 option values and onChange callback 
 * @returns Selector component with unique option values
 */
export function Dealer({ value='',options, onChange }) {
    const handleDealerChange = (event) => {
        const selectedOption = event.target.value;
        onChange(selectedOption);
    }
    return (
        <Flex alignItems="center">
            <FormLabel htmlFor="Dealer" 
                mb="0" mr="2"
                color="gray.600" fontWeight="bold"
                fontSize="sm">
                Dealer No
            </FormLabel>
            <Select 
                id="dealer"
                placeholder='Select All'
                onChange={handleDealerChange}
                value={value}
            >
                {options.map((value, index) => (
                    <option value={value} key={index}>{value}</option>
                ))}
            </Select>
        </Flex>
    )
};