import { Tooltip } from '@chakra-ui/react';
import React from 'react';

function TextWrapRenderer({value,hover}) {
  return (
    <Tooltip label={hover}>{value}</Tooltip>
      
    
  );
}

export default TextWrapRenderer;