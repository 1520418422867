import React, { useState, useEffect } from "react";
import {
  Select,
  Flex,
  FormLabel,
  HStack,
  Box,
  Text,
  Button,
  Spacer,
} from "@chakra-ui/react";
import { IoMdDownload } from "react-icons/io";
import {
  RowNameMappingOtherTable,
  createGroupedOtherDataReport,
} from "./OtherCanvasHelper";
import "../../../../index";
import useFetch from "../../../../hooks/useFetchWithMsal";
import { CustomSpinner } from "../../../Spinner";
import { FormatDateToMonthYear } from "../../../../Constants";
import { GetUniqueValues } from "../SKULevel/SKUCanvasHelper";
import { filterVariableKeys12 } from "./OtherCanvasHelper";
import TextWrapRenderer from "../TextWrapRenderer";
import { highLightingKeys } from "./OtherCanvasHelper";
import { ReactGrid } from "@silevis/reactgrid";
import "./../style.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export default function OtherTableReport({ userProfile }) {
  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const [dealer_filter, setDealer_filter] = useState("");
  const dealer_no_all = GetUniqueValues(data, "dealer_no_all");
  const uniqueDates = GetUniqueValues(data, "Date");
  const [{ loading }, fetchData] = useFetch([]);
  const [groupedData, setGroupedData] = useState(
    createGroupedOtherDataReport(data, dealer_filter, filter)
  );
  const categories = [
    ...new Set(Object.values(RowNameMappingOtherTable).map((arr) => arr[0])),
  ];

  useEffect(() => {
    fetchData(`/regionalDistributor?userID=${userProfile["ID"]}`, {
      method: "GET",
    }).then((jsonData) => {
      setGroupedData(
        createGroupedOtherDataReport(jsonData, dealer_filter, filter)
      );
      setData(jsonData);
      setColumns(getColumns(GetUniqueValues(jsonData, "Date")));
    });
  }, [userProfile, fetchData]);

  const exportToCSV = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "CRMandRevenueReport";
    const wb = XLSX.utils.book_new();
    const arrayToPrint = rows.map((item) => {
      return item["cells"].map((item2) => {
        return item2["text"] ? item2["text"] : item2["value"];
      });
    });
    const ws = XLSX.utils.aoa_to_sheet(arrayToPrint);

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data1 = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data1, fileName + fileExtension);
  };

  useEffect(() => {
    if (!userProfile) return;
    setGroupedData(createGroupedOtherDataReport(data, dealer_filter, filter));
  }, [userProfile, data, dealer_filter, filter]);

  const getColumns = (uniqueDates) => {
    if (uniqueDates.length === 0) return [];
    return [
      { columnId: "dealer_no", width: 150, resizable: true },
      { columnId: "category", width: 150, resizable: true },
      { columnId: "key_figure", width: 150, resizable: true },
      ...uniqueDates.map((column) => {
        return { columnId: column, width: 90, resizable: true };
      }),
      { columnId: "Totals", width: 90, resizable: true },
    ];
  };

  const [columns, setColumns] = useState(getColumns(uniqueDates));
  const handleColumnResize = (ci, width) => {
    if (uniqueDates.length === 0) return [];
    setColumns((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const getRows = (groupedData) => {
    if (Object.keys(uniqueDates).length === 0) return [];
    const headerRowCells = uniqueDates.map((column) => {
      return {
        type: "header",
        text: FormatDateToMonthYear(column),
        className: "header-class",
      };
    });
    let rows = [
      {
        rowId: "header",
        cells: [
          { type: "header", text: "Dealer Number", className: "header-class" },
          { type: "header", text: "Category", className: "header-class" },
          { type: "header", text: "Key Figure", className: "header-class" },
          ...headerRowCells,
          { type: "header", text: "Totals", className: "header-class" },
        ],
      },
    ];

    let dictionaryRow = [...rows];
    Object.keys(groupedData).forEach((rowName, index1) => {
      const rowMappingKeys253 =
        filter === "" || filter === null || filter === undefined
          ? Object.keys(RowNameMappingOtherTable)
          : Object.keys(RowNameMappingOtherTable).filter((value) =>
              filterVariableKeys12[filter].includes(value)
            );
      rowMappingKeys253.forEach((key_figure_name, index2) => {
        let oneSingleRow = {
          rowId: `${rowName}__${key_figure_name}`,
          cells: [
            {
              type: "text",
              nonEditable: true,
              text: groupedData[rowName][0]["dealer_no"],
              renderer: (text) => {
                return (
                  <TextWrapRenderer
                    value={text}
                    hover={groupedData[rowName][0]["dealer_name"]}
                  />
                );
              },
              className: highLightingKeys.includes(key_figure_name)
                ? "row-class"
                : "row-class2",
            },
            {
              type: "text",
              nonEditable: true,
              text: RowNameMappingOtherTable[key_figure_name][0],
              renderer: (text) => {
                return <TextWrapRenderer value={text} hover={text} />;
              },
              className: highLightingKeys.includes(key_figure_name)
                ? "row-class"
                : "row-class2",
            },
            {
              type: "text",
              nonEditable: true,
              text: filterVariableKeys12["Purchases"].includes(key_figure_name)
                ? RowNameMappingOtherTable[key_figure_name][1] + " $"
                : RowNameMappingOtherTable[key_figure_name][1],
              renderer: (text) => {
                return <TextWrapRenderer value={text} hover={text} />;
              },
              className: highLightingKeys.includes(key_figure_name)
                ? "row-class"
                : "row-class2",
            },
            ...groupedData[rowName].map((value) => {
              return {
                type: "number",
                nonEditable: true,
                className: highLightingKeys.includes(key_figure_name)
                  ? "number-class1"
                  : "number-class2",
                value: isNaN(parseFloat(value[key_figure_name]))
                  ? ""
                  : Math.round(parseFloat(value[key_figure_name])),
              };
            }),
          ],
        };
        dictionaryRow.push(oneSingleRow);
      });
    });
    return dictionaryRow;
  };
  const rows = getRows(groupedData);

  if (loading) return <CustomSpinner text="Loading Report..." />;

  return (
    <Box height="70vh">
      <HStack>
        <Flex alignItems="center">
          <FormLabel
            htmlFor="category"
            mb="0"
            mr="2"
            color="gray.600"
            fontWeight="bold"
            fontSize="sm"
          >
            Category
          </FormLabel>
          <Select
            id="category"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="">All</option>
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex alignItems="center">
          <FormLabel
            htmlFor="dealer_no"
            mb="0"
            mr="2"
            color="gray.600"
            fontWeight="bold"
            fontSize="sm"
          >
            Dealer No
          </FormLabel>
          <Select
            id="dealer_no"
            value={dealer_filter}
            onChange={(e) => setDealer_filter(e.target.value)}
          >
            <option value="">Select All</option>
            {dealer_no_all &&
              dealer_no_all.map((dealer_no, index) => (
                <option key={index} value={dealer_no}>
                  {dealer_no}
                </option>
              ))}
          </Select>
        </Flex>
        <Button
          leftIcon={<IoMdDownload />}
          onClick={exportToCSV}
          colorScheme="blue"
        >
          Export
        </Button>
        <Spacer />
        <Text
          fontSize="sm" // Adjust the font size to your preference
          fontWeight="bold" // Apply bold font weight to the caption
          color="gray.500" // Use a gray color for the caption
          marginBottom="2" // Add some bottom margin for spacing
        >
          *Shows represent cooked shows
        </Text>
      </HStack>
      <Box height="65vh" overflow="auto" className="react-grid">
        <ReactGrid
          rows={rows}
          columns={columns}
          disableVirtualScrolling={true}
          stickyLeftColumns={3}
          stickyRightColumns={1}
          stickyTopRows={1}
          onColumnResized={handleColumnResize}
          enableColumnSelection
          enableRangeSelection
          enableRowSelection
        />
      </Box>
    </Box>
  );
}
