import React, { useCallback, useEffect, useState } from 'react';
import { Box, HStack, Table, Tbody, Th, Thead, Tr, Td, Spacer, Text, Button } from '@chakra-ui/react';
import SaveButton from '../../Save';
import useFetch from '../../../hooks/useFetchWithMsal';
import { FormatDateToMonthYear } from './RegionalCanvasHelper';
import { pivotParseData, updateTotals } from './RegionalCanvasHelper';
import { parentRows } from './RegionalCanvasHelper';
import {IoMdDownload } from 'react-icons/io';
import { ReactGrid } from '@silevis/reactgrid';
// import TextWrapRenderer from './TextWrapRenderer';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";


// function getWindowDimensions() {
//     const { innerWidth: width, innerHeight: height } = window;
//     return {
//       width,
//       height
//     };
//   }

export default function RegionalTable({ userProfile, error, saveEnabled, data, setDataSaved, account_id }) {
    const [, updateData] = useFetch();
    // const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    // const [json_data,setJson_data]=useState(data)
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [changesTracker, setChangesTracker] = useState({});
    const [pivotedData, setPivotedData] = useState({})
    const [totals, setTotals] = useState({})

    useEffect(() => {
        pivotParseData(data, setPivotedData, setTotals,setColumns,getColumns)
    }, [data]);


    const getColumns = (pivotedData) => {
        if (Object.keys(pivotedData).length === 0) return [];
        return [{ columnId: "key_figure",width:300, resizable: true },
        ...Object.keys(pivotedData).map((column) => {
            return { columnId: column,width:90, resizable: true }
        }),
        { columnId: "Totals",width:90, resizable: true }];
    }

    const exportToCSV = () => {
        const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const fileName = "RegionalView"
        const wb = XLSX.utils.book_new();
        const arrayToPrint = rows.map((item)=>{
            return item["cells"].map((item2)=>{
              return item2["text"]?item2["text"]:item2["value"]
            })
        })
        const ws = XLSX.utils.aoa_to_sheet(arrayToPrint);
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data1 = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data1, fileName + fileExtension);
      };

    const getRows = (pivotedData) => {
        if (Object.keys(pivotedData).length === 0) return [];
        const headerRowCells = Object.keys(pivotedData).map((column) => {
            return { type: "header", text: FormatDateToMonthYear(column), className: "header-class" }
        })
        let rows = [
            {
                rowId: "header",
                cells: [{ type: "header", text: "Key Figure", className: "header-class" }, ...headerRowCells, { type: "header", text: "Totals", className: "header-class" }]
            },
        ]

        let dictionaryRow = [...rows]
        Object.entries(parentRows).forEach(([parentRowName, value1]) => {
            let oneSingleRow = {
                rowId: `${parentRowName}`,
                cells: [{ type: "text", text: parentRowName, isExpanded: true, className: "ParentRow", nonEditable: true },
                ...Object.entries(pivotedData).map(([key, value]) => {
                    return { type: "text", nonEditable: true, text: "", className: parentRowName }
                }
                ), { type: "text", text: "" }]
            }
            dictionaryRow.push(oneSingleRow)
            value1.forEach((childRowName) => {
                let twoSingleRow = {
                    rowId: `${childRowName}`,
                    cells: [{ type: "chevron", nonEditable: true, text:childRowName==="Current Fcst - Total"?childRowName+" ✎":childRowName, indent: 2, parentId: `${parentRowName}`, className: childRowName.includes("Current Fcst") ?childRowName==="Current Fcst - Total"?"row-class3":"row-class":"row-class2" },
                    ...Object.entries(pivotedData).map(([key, value]) => {
                        return { type: "number", nonEditable: childRowName === 'Current Fcst - Total' ? false : true, value: isNaN(parseFloat(value[childRowName])) ? "" : parseFloat(value[childRowName].toFixed(0)), className: childRowName.includes("Current Fcst") ?childRowName==="Current Fcst - Total"?(value["Current Fcst - Total"]!==value["Current Adjusted Revenue"])?"test-class3":"test-class4":"number-class1": "number-class2" }
                    }
                    ), { type: "number", nonEditable: true, value: isNaN(parseFloat(totals[childRowName])) ? "" : parseFloat(totals[childRowName].toFixed(0)), className: childRowName.includes("Current Fcst") ?childRowName==="Current Fcst - Total"?"test-class4":"number-class1" : "number-class2" }]
                }
                dictionaryRow.push(twoSingleRow)
            })
        })
        return dictionaryRow;
    }
    const handleColumnResize = (ci, width) => {
        if (Object.keys(pivotedData).length ===0) return [];
        setColumns((prevColumns) => {
            const columnIndex = prevColumns.findIndex(el => el.columnId === ci);
            const resizedColumn = prevColumns[columnIndex];
            const updatedColumn = { ...resizedColumn, width };
            prevColumns[columnIndex] = updatedColumn;
            return [...prevColumns];
        });
    }

    const rows = getRows(pivotedData)
    const [columns, setColumns] = useState(getColumns(pivotedData));


    const uploadData = () => {
        setIsButtonDisabled(true);
        let data = [];
        Object.entries(changesTracker).forEach(([key, newValue]) => {
            const [rowId, columnID, forecast_version_date] = key.split(",");
            data.push({
                date: columnID,
                newValue: newValue === "" ? null : parseFloat(newValue),
                sku_code: rowId,
                regional_leader_id: userProfile,
                forecast_version: forecast_version_date,
                update_date: new Date().toISOString(),
                update_by: account_id
            });
        });
        updateData("/regionalViewUpdateNonForecast", { method: "PUT", body: data }).then((data) => {
            if (data.Error) return;
        }).then(() => {
            // updateTotals(totals, pivotedData, setTotals, setPivotedData, changesTracker)
            setDataSaved(true)
            setChangesTracker({})
        }).catch((error) => {
            console.log(error)
        }

        );

    };

    const handleChanges = (changes) => {
        setIsButtonDisabled(false);
        setDataSaved(false)
        changes.forEach((change) => {
            setChangesTracker((prevChanges) => {
                const updatedChanges = { ...prevChanges };
                const key = `All others,${change.columnId},${pivotedData[change.columnId]["Forecast Version"]}`;
                updatedChanges[key] = change.newCell.value<0?null:change.newCell.text !== '' ? pivotedData[change.columnId]["Current Fcst - Focus Dealer"] ? (parseFloat(change.newCell.value) - pivotedData[change.columnId]["Current Fcst - Focus Dealer"]) : parseFloat(change.newCell.value) : null;
                return updatedChanges;
            });
        })
        let totalsTemp= totals
        let pivotedDatatemp=pivotedData
        changes.forEach((change)=>{
            const date = change.columnId
            let value=change.newCell.value<0?null:change.newCell.text !== '' ? pivotedData[change.columnId]["Current Fcst - Focus Dealer"] ? (parseFloat(change.newCell.value) - pivotedData[change.columnId]["Current Fcst - Focus Dealer"]) :parseFloat(change.newCell.value) : null;

            if(value!==null){
            let calcRegionalRevenueForecast =pivotedData[date]["Current Fcst - Focus Dealer"]? parseFloat(value)+pivotedData[date]["Current Fcst - Focus Dealer"]:parseFloat(value)
            totalsTemp["Current Fcst - Total"]=totalsTemp["Current Fcst - Total"]-pivotedData[date]["Current Fcst - Total"]+calcRegionalRevenueForecast
            totalsTemp["Adjusted Regional Revenue Forecast"]=totalsTemp["Adjusted Regional Revenue Forecast"]-pivotedData[date]["Adjusted Regional Revenue Forecast"]+calcRegionalRevenueForecast
            totalsTemp["Calc Current Fcst - Non Focus Dealer"]=totalsTemp["Calc Current Fcst - Non Focus Dealer"]-pivotedData[date]["Calc Current Fcst - Non Focus Dealer"]+parseFloat(value)
            pivotedDatatemp[date]["Calc Current Fcst - Non Focus Dealer"]= parseFloat(value)
            pivotedDatatemp[date]["Current Fcst - Total"]=calcRegionalRevenueForecast
            pivotedDatatemp[date]["Adjusted Regional Revenue Forecast"]=calcRegionalRevenueForecast
            }
            else{
            totalsTemp["Current Fcst - Total"]=totalsTemp["Current Fcst - Total"]-pivotedData[date]["Current Fcst - Total"]+pivotedData[date]["Current Adjusted Revenue"]
            totalsTemp["Adjusted Regional Revenue Forecast"]=totalsTemp["Adjusted Regional Revenue Forecast"]-pivotedData[date]["Adjusted Regional Revenue Forecast"]+pivotedData[date]["Current Adjusted Revenue"]
            totalsTemp["Calc Current Fcst - Non Focus Dealer"]=totalsTemp["Calc Current Fcst - Non Focus Dealer"]-pivotedData[date]["Calc Current Fcst - Non Focus Dealer"]+pivotedData[date]["Stat Fcst - Non Focus Dealer"]
            pivotedDatatemp[date]["Current Fcst - Total"]=pivotedData[date]["Current Adjusted Revenue"]
            pivotedDatatemp[date]["Calc Current Fcst - Non Focus Dealer"]=pivotedData[date]["Stat Fcst - Non Focus Dealer"]
            }
        })
        setPivotedData(pivotedDatatemp)
        setTotals(totalsTemp)
    }


    return (
        <Box minHeight="70vh">
            <HStack spacing={4}>
                <SaveButton isButtonDisabled={(!saveEnabled) || isButtonDisabled} uploadData={uploadData} />
                <Button leftIcon={<IoMdDownload/>} style={{ marginTop: "16px" }} px={6} py={2} fontSize="md" fontWeight="bold" onClick={exportToCSV} colorScheme='blue'>
                    Export
                </Button>
                <Spacer />
            </HStack>
            <Box height="60vh" overflow="auto" textAlign="center" id='printableArea' className='react-grid'>
                <ReactGrid rows={rows} columns={columns} onCellsChanged={handleChanges} stickyTopRows={1} stickyLeftColumns={1} disableVirtualScrolling={true} stickyRightColumns={1} onColumnResized={handleColumnResize} enableColumnSelection enableRangeSelection enableRowSelection></ReactGrid>
            </Box>
        </Box>

    );
};

