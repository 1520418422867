import React, { useEffect, useState, useMemo } from 'react';
import { Box, HStack, Spacer, Text } from '@chakra-ui/react';
import { ProductLine, Category,Dealer } from './Filter';
import { FormatDateToMonthYear } from '../../../../Constants';
import { SKURowNames, GetUniqueValues } from './SKUCanvasHelper';
import { CustomSpinner } from '../../../Spinner';
import SaveButton from '../../../Save';
import useFetch from '../../../../hooks/useFetchWithMsal';
import { SKUTypelist } from './SKUCanvasHelper';
import { ReactGrid} from "@silevis/reactgrid";
import { createGroupedData } from './SKUCanvasHelper';
import './../style.css'
import TextWrapRenderer from '../TextWrapRenderer';

export default function SkuTable ({ userProfile,setDataSaved,account_id }) {
  const [changesTracker, setChangesTracker] = useState({});
  const [data,setData]=useState([])
  const [{ loading, error }, fetchData] = useFetch([]);
  const uniqueDates = GetUniqueValues(data, 'date'); // Get all the rolling 12 month date
  const uniqueProductLine = GetUniqueValues(data, 'Product_Line'); // Get all unique product line info
  const uniqueProductCategory = useMemo(() => GetUniqueValues(data, 'Product_Category'), [data]);
  const uniqueDealers=GetUniqueValues(data,'dealer_no_all')
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selectedProductLine, setSelectedProductLine] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedDealer,setSelectedDealer]=useState('')
  const [filteredUniqueProductCategory, setFilteredUniqueProductCategory] = useState(uniqueProductCategory);
  const [, updateData] = useFetch();
  const [groupedData,setGroupedData]=useState(createGroupedData(data,selectedDealer,selectedCategory,selectedProductLine))
  

  useEffect(() => {
    if (!userProfile) return;
    fetchData(`/regional_products?userID=${userProfile}`, {method: "GET"}).then((data1) => {
      const uniqueProductCategory = GetUniqueValues(data1, 'Product_Category');
      setData(data1)
      setFilteredUniqueProductCategory(uniqueProductCategory);
      setSelectedDealer('')
      setIsButtonDisabled(true);
      setDataSaved(true)
      setChangesTracker({})
      setColumns(getColumns(GetUniqueValues(data1,'date')))
      setGroupedData(createGroupedData(data1,'','',''))
    })
  }, [userProfile ,setFilteredUniqueProductCategory, fetchData,setDataSaved]);

  useEffect(() => {
    if (!userProfile) return;
    setGroupedData(createGroupedData(data,selectedDealer,selectedCategory,selectedProductLine))
}, [userProfile,data ,selectedDealer, selectedCategory,selectedProductLine]);

  /**
   * 
   * @param {*} newValue updated value
   * @param {*} columnID date to update the value
   * @param {*} rowId sku code for updated value
   * Handle all the changes to the editable cell in the table
   */

  const handleChangesTracker =(changes) => {
    setIsButtonDisabled(false);
    setDataSaved(false)
    setGroupedData((prevData)=>{
      changes.forEach((change)=>{
      var key=change.rowId.split('__')
      prevData[key[0]+"__"+key[1]+"__"+key[2]].map((value)=>{
        if(value["date"]===change.columnId){
          value[key[3]]=change.newCell.value<0?null:change.newCell.value
        }
        return value
      })
      })
      return {...prevData}
    })
    setChangesTracker((changesTracK)=>{
      const prevData={...changesTracK}
      changes.forEach((change)=>{
        var key=change.rowId.split('__')
        prevData[`${key[1]},${key[0]},${change.columnId},${key[2]}`]=change.newCell.value<0?null:change.newCell.value
    })
    return prevData
    })
  };

  /**
   * Upload data once after enter key is pressed. Once the data is successfullu
   * updated the changes tracker is emptied and a success Toast is shown.
   */
  const uploadData = () => {
    let data = []
    Object.entries(changesTracker).forEach(([key, newValue]) => {
      const [rowId,dealer_no,columnID,forecast_version_date] = key.split(",");

      data.push({ 
        date: columnID, 
        newValue: newValue === ""||isNaN(newValue)||newValue===null ? null : parseFloat(newValue).toFixed(0), 
        sku_code: rowId, 
        dealer_no: dealer_no,
        forecast_version_date:forecast_version_date,
        update_by:account_id,
        update_date:new Date().toISOString()
      });
    })
    updateData("/update-data", {method: "PUT", body: data}).then((data) => {
      setChangesTracker({});
      setIsButtonDisabled(true);
      setDataSaved(true)
    }).catch((error) => {
      console.log(error)
  });

  };


  /**
   * 
   * @param {dict} data to be converted into table rows
   * @param {int} itemIndex index of each item
   * @returns {DataRow} each row in the table
   */

  const getColumns = (uniqueDates) => {
    if (uniqueDates.length === 0) return [];
    return [{ columnId: "dealer_no", width: 150, resizable:true},
            { columnId: "SKU_Description", width: 150, resizable:true},
            { columnId: "SKU_Code", width: 150, resizable:true},
            { columnId: "Key_Figure", width: 150, resizable:true}, 
            ...uniqueDates.map((column) => {
      return { columnId: column, width: 75, resizable:true}
    })];
  }

  const getRows = (groupedData) => {
    if (Object.keys(uniqueDates).length === 0) return [];
    const headerRowCells = uniqueDates.map((column) => {
      return { type: "header", text: FormatDateToMonthYear(column),className:"header-class" }
    })
    let rows = [
      {
        rowId: "header",
        cells: [{ type: "header", text: "Dealer Number",className:"header-class" },{ type: "header", text: "SKU Description",className:"header-class"  },{ type: "header", text: "SKU Code",className:"header-class"  },
        { type: "header", text: "Key Figure",className:"header-class"  }, ...headerRowCells]
      },
    ]
  
  let dictionaryRow=[...rows]
  Object.keys(groupedData).forEach((rowName,index1) => {
    SKURowNames.forEach((key_figure_name,index2)=>{
      const className1 =key_figure_name==='Current Forecast'?"number-class1":"number-class2" 
      let oneSingleRow =  {
        rowId: `${rowName}__${SKUTypelist[index2]}`,
        cells: [{type:"text",nonEditable:true,text:groupedData[rowName][0]["dealer_no"],renderer:(text)=>{return <TextWrapRenderer value={text} hover={groupedData[rowName][0]["dealer_name"]} />},className:key_figure_name==='Current Forecast'?"row-class":"row-class2"},
        {type:"text",nonEditable:true,text:groupedData[rowName][0]["SKU_Description"],renderer:(text)=>{return <TextWrapRenderer value={text} hover={text} />},className:key_figure_name==='Current Forecast'?"row-class":"row-class2"},
        {type:"text",nonEditable:true,text:groupedData[rowName][0]["SKU_code"],className:key_figure_name==='Current Forecast'?"row-class":"row-class2"},
        {type:"text",nonEditable:true,text:key_figure_name==="Current Forecast"?key_figure_name+" ✎":key_figure_name,className:key_figure_name==='Current Forecast'?"row-class":"row-class2"},
        ...groupedData[rowName].map((value) => {
          return { type: "number",nonEditable:key_figure_name==='Current Forecast'?false:true ,value: isNaN(parseFloat(value[SKUTypelist[index2]])) ? "": parseFloat(value[SKUTypelist[index2]]),className:className1 }
        }
        )]
      }
    dictionaryRow.push(oneSingleRow)
    })
  })
    return dictionaryRow;
  }


  const handleProductLineChange = (selectedOption) => {
    setSelectedProductLine(selectedOption);
    setSelectedCategory('');
    const filteredCategories = data
    .filter(item => selectedOption ? item['Product_Line'] === selectedOption : true)
    .map(item => item['Product_Category']);
    setFilteredUniqueProductCategory([...new Set(filteredCategories)]);
  }
  const handleCategoryChange = (selectedOption) => setSelectedCategory(selectedOption);
  const handleDealerChange = (selectedOption)=>setSelectedDealer(selectedOption)
  

  const rows=getRows(groupedData)
  const [columns, setColumns] = useState(getColumns(uniqueDates));
  const handleColumnResize = (ci, width) => {
    if (uniqueDates.length ===0) return [];
    setColumns((prevColumns) => {
        const columnIndex = prevColumns.findIndex(el => el.columnId === ci);
        const resizedColumn = prevColumns[columnIndex];
        const updatedColumn = { ...resizedColumn, width };
        prevColumns[columnIndex] = updatedColumn;
        return [...prevColumns];
    });
}

  /*
   * Show Custom Spinner while data is being fetched.
  */
  if (loading) return <CustomSpinner text="Loading Editor..." />

  return (
    <Box height="75vh">
      <HStack spacing={4}>
        <ProductLine value={selectedProductLine} options={uniqueProductLine} onChange={handleProductLineChange}/>
        <Category value={selectedCategory} options={filteredUniqueProductCategory} onChange={handleCategoryChange}/>
        <Dealer value={selectedDealer} options={uniqueDealers} onChange={handleDealerChange}/>
        <SaveButton uploadData={uploadData} isButtonDisabled={isButtonDisabled} />
        <Spacer />
        <Text
        fontSize="sm" // Adjust the font size to your preference
        fontWeight="bold" // Apply bold font weight to the caption
        color="gray.500" // Use a gray color for the caption
        marginBottom="2" // Add some bottom margin for spacing
        >* Data in units</Text>
      </HStack>
      <Box alignSelf={"center"} className='react-grid' >
        <ReactGrid  rows={rows} disableVirtualScrolling={true} columns={columns} stickyTopRows={1} stickyLeftColumns={4}  onColumnResized={handleColumnResize} onCellsChanged={handleChangesTracker}/>
      </Box>
    </Box>
  );
};
