import React, { useState, useEffect } from 'react';
import { Box, HStack, Select, Flex, FormLabel, Text,Spacer } from '@chakra-ui/react';
// import { UpdateOtherTableData } from './OtherCanvasHelper';
import SaveButton from '../../../Save';
import { RowNameMappingOtherTable, filterVariableKeys12, highLightingKeys } from './OtherCanvasHelper';
import useFetch from '../../../../hooks/useFetchWithMsal';
import { GetUniqueValues } from '../SKULevel/SKUCanvasHelper';
import { createGroupedOtherData } from './OtherCanvasHelper';
import { ReactGrid } from '@silevis/reactgrid';
import "./../style.css"
import { FormatDateToMonthYear } from '../../../../Constants';
import TextWrapRenderer from '../TextWrapRenderer';


export default function OtherCanvas({ userProfile,setDataSaved,account_id }) {
    const [{ loading }, fetchData] = useFetch([]);
    const [data,setData]=useState([])
    const [changesTracker, setChangesTracker] = useState({});
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [filter, setFilter] = useState('');
    const dealer_no_all=GetUniqueValues(data,'dealer_no_all')
    const uniqueDates = GetUniqueValues(data, 'Date')
    const [dealer_filter,setDealer_filter]=useState('')
    const [, updateData] = useFetch()
    const categories = [...new Set(Object.values(RowNameMappingOtherTable).map(arr => arr[0]))];
    const [groupedData,setGroupedData]=useState(createGroupedOtherData(data,dealer_filter,filter))



    useEffect(() => {
        fetchData(`/regionalDistributor?userID=${userProfile}`, { method: "GET" }).then((jsonData) => {
            console.log(jsonData)
            setGroupedData(createGroupedOtherData(jsonData,dealer_filter,filter))
            setData(jsonData)
            setDataSaved(true)
            setIsButtonDisabled(true);
            setColumns(getColumns(GetUniqueValues(jsonData,"Date")))
            setChangesTracker({})
        })
    }, [userProfile, fetchData,setDataSaved]);

    useEffect(() => {
        if (!userProfile) return;
        setGroupedData(createGroupedOtherData(data,dealer_filter,filter))
    }, [userProfile,data ,dealer_filter, ,filter]);
    

    const uploadData = () => {
        if (!userProfile) return;
        let data = []
        Object.entries(changesTracker).forEach(([key,newValue]) => {
          console.log(key)
            const [rowId, columnID,forecast_version,dealer_no] = key.split(",");
            data.push({ 
                "date": columnID, 
                "newValue": newValue === ''||isNaN(newValue)||newValue===null ? null : parseFloat(newValue).toFixed(0),
                "dealer_no": dealer_no, 
                "category": rowId,
                "forecast_version":forecast_version,
                "update_by":account_id,
                "update_date":new Date().toISOString()
            });
        });
        console.log("changes tracker",data)
        updateData("/updateDistForecast", {method: "PUT", body: data}).then((data) => {
            setIsButtonDisabled(true)
            setChangesTracker({});
            setDataSaved(true)
        }).catch((error) => {
            console.log(error)
        });
    };

    const handleChangesTracker =(changes) => {
        setIsButtonDisabled(false);
        setDataSaved(false)
        setGroupedData((prevData)=>{
          changes.forEach((change)=>{
          var key=change.rowId.split('__')
          prevData[key[0]+"__"+key[1]].map((value)=>{
            if(value["Date"]===change.columnId){
              value[key[2]]=change.newCell.value<0?null:change.newCell.value
            }
            return value
          })
          })
          return {...prevData}
        })
        setChangesTracker((changesTracK)=>{
          const prevData={...changesTracK}
          changes.forEach((change)=>{
            var key=change.rowId.split('__')
            prevData[`${key[2]},${change.columnId},${key[1]},${key[0]}`]=change.newCell.value<0?null:change.newCell.value
        })
        return prevData
        })
      };
      console.log(changesTracker)

    

    const getColumns = (uniqueDates) => {
        if (uniqueDates.length === 0) return [];
        return [{ columnId: "dealer_no", width: 150, resizable:true},
                { columnId: "category", width: 150, resizable:true},
                { columnId: "key_figure", width: 150, resizable:true},
                ...uniqueDates.map((column) => {
          return { columnId: column, width: 90, resizable:true}
        })];
      }

  const [columns, setColumns] = useState(getColumns(uniqueDates));
  const handleColumnResize = (ci, width) => {
    if (uniqueDates.length ===0) return [];
    setColumns((prevColumns) => {
        const columnIndex = prevColumns.findIndex(el => el.columnId === ci);
        const resizedColumn = prevColumns[columnIndex];
        const updatedColumn = { ...resizedColumn, width };
        prevColumns[columnIndex] = updatedColumn;
        return [...prevColumns];
    });
}
    
      const getRows = (groupedData) => {
        if (Object.keys(uniqueDates).length === 0) return [];
        const headerRowCells = uniqueDates.map((column) => {
          return { type: "header", text: FormatDateToMonthYear(column),className:"header-class"}
        })
        let rows = [
          {
            rowId: "header",
            cells: [{ type: "header", text: "Dealer Number",className:"header-class" },{ type: "header", text: "Category",className:"header-class"  },{ type: "header", text: "Key Figure",className:"header-class"  }, ...headerRowCells]
          },
        ]
      
      let dictionaryRow=[...rows]
      Object.keys(groupedData).forEach((rowName,index1) => {
        const rowMappingKeys253=filter===""||filter===null||filter===undefined?Object.keys(RowNameMappingOtherTable):Object.keys(RowNameMappingOtherTable).filter(value=>filterVariableKeys12[filter].includes(value))
        rowMappingKeys253.forEach((key_figure_name,index2)=>{
            let oneSingleRow =  {
            rowId: `${rowName}__${key_figure_name}`,
            cells: [{type:"text",nonEditable:true,text:groupedData[rowName][0]["dealer_no"],renderer:(text)=>{return <TextWrapRenderer value={text} hover={groupedData[rowName][0]["dealer_name"]} />},className:highLightingKeys.includes(key_figure_name)?"row-class":"row-class2"},
            {type:"text",nonEditable:true,text:RowNameMappingOtherTable[key_figure_name][0],renderer:(text)=>{return <TextWrapRenderer value={text} hover={text}/>},className:highLightingKeys.includes(key_figure_name)?"row-class":"row-class2"},
            {type:"text",nonEditable:true,text:filterVariableKeys12["Purchases"].includes(key_figure_name)?highLightingKeys.includes(key_figure_name)?RowNameMappingOtherTable[key_figure_name][1]+" $" +" ✎":RowNameMappingOtherTable[key_figure_name][1]+" $":highLightingKeys.includes(key_figure_name)?RowNameMappingOtherTable[key_figure_name][1]+" ✎":RowNameMappingOtherTable[key_figure_name][1],renderer:(text)=>{return <TextWrapRenderer value={text} hover={text} />},className:highLightingKeys.includes(key_figure_name)?"row-class":"row-class2"},
            ...groupedData[rowName].map((value) => {
              return { type: "number",nonEditable:highLightingKeys.includes(key_figure_name)?false:true,className:highLightingKeys.includes(key_figure_name)?"number-class1":"number-class2",value: isNaN(parseFloat(value[key_figure_name])) ? "": Math.round(parseFloat(value[key_figure_name])) }
            }
            )]
          }
        dictionaryRow.push(oneSingleRow)
        })
      })
        return dictionaryRow;
      }
      const rows=getRows(groupedData)
      console.log(rows)

    return (
        <Box height="70vh">
            <HStack spacing={4}>
            <Flex alignItems="center">
            <FormLabel htmlFor="category" 
                mb="0" mr="2"
                color="gray.600" fontWeight="bold"
                fontSize="sm">
                Category
            </FormLabel>
            <Select id="category" value={filter} onChange={e => setFilter(e.target.value)}>
                <option value="">All</option>
                {categories.map((category, index) => (
                    <option key={index} value={category}>{category}</option>
                ))}
            </Select>
            </Flex>
            <Flex alignItems="center">
            <FormLabel htmlFor="dealer_no" 
                mb="0" mr="2"
                color="gray.600" fontWeight="bold"
                fontSize="sm">
                Dealer No
            </FormLabel>
            <Select id="dealer_no" value={dealer_filter} onChange={e => setDealer_filter(e.target.value)}>
                <option value="">Select All</option>
                {dealer_no_all && dealer_no_all.map((dealer_no, index) => (
                    <option key={index} value={dealer_no}>{dealer_no}</option>
                ))}
            </Select>
            </Flex>
            <SaveButton uploadData={uploadData} isButtonDisabled={isButtonDisabled} />
            <Spacer />
        <Text
        fontSize="sm" // Adjust the font size to your preference
        fontWeight="bold" // Apply bold font weight to the caption
        color="gray.500" // Use a gray color for the caption
        marginBottom="2" // Add some bottom margin for spacing
        >*Shows represent cooked shows</Text>
            
            </HStack>
            <Box  alignSelf={"center"} className='react-grid' >
                <ReactGrid  rows={rows} columns={columns} stickyLeftColumns={3} stickyTopRows={1} onCellsChanged={handleChangesTracker} disableVirtualScrolling={true} onColumnResized={handleColumnResize} />
            </Box>
        </Box>
    );

};